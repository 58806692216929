<template>
    <div class="d-flex justify-content-center">
        <el-tooltip content="Pedido de Venda" effect="light" :open-delay="300" placement="top"
            v-if="erpDocuments.Order && showIcon()">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                class="iconify iconify--mdi" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"
                @click="showModal('2')">
                <path :fill="getStatus('DocEntry')"
                    d="M17 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2M1 2v2h2l3.6 7.59l-1.36 2.45c-.15.28-.24.61-.24.96a2 2 0 0 0 2 2h12v-2H7.42a.25.25 0 0 1-.25-.25c0-.05.01-.09.03-.12L8.1 13h7.45c.75 0 1.41-.42 1.75-1.03l3.58-6.47c.07-.16.12-.33.12-.5a1 1 0 0 0-1-1H5.21l-.94-2M7 18c-1.11 0-2 .89-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2Z">
                </path>
            </svg>
        </el-tooltip>
        <el-tooltip content="Picking" effect="light" :open-delay="300" placement="top"
            v-if="erpDocuments.Picking && showIcon()">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                class="iconify iconify--mdi" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"
                @click="showModal('7')">
                <path :fill="getStatus('DocEntryPicking')"
                    d="M18 18.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5s.67 1.5 1.5 1.5m1.5-9H17V12h4.46L19.5 9.5M6 18.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5s.67 1.5 1.5 1.5M20 8l3 4v5h-2c0 1.66-1.34 3-3 3s-3-1.34-3-3H9c0 1.66-1.34 3-3 3s-3-1.34-3-3H1V6c0-1.11.89-2 2-2h14v4h3M3 6v9h.76c.55-.61 1.35-1 2.24-1c.89 0 1.69.39 2.24 1H15V6H3Z">
                </path>
            </svg>
        </el-tooltip>
        <el-tooltip :content="getContentDocEntryNF()" effect="light" :open-delay="300" placement="top"
            v-if="erpDocuments.Invoice">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                class="iconify iconify--mdi" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"
                @click="showModal(taxOperation === 'Retorno para Transferência' ? '15' : '6')">
                <path :fill="getStatus('DocEntryNf')"
                    d="M6 2a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8l-6-6H6m0 2h7v5h5v11H6V4m2 8v2h8v-2H8m0 4v2h5v-2H8Z">
                </path>
            </svg>
        </el-tooltip>
        <el-tooltip content="Transferência de Estoque" effect="light" :open-delay="300" placement="top"
            v-if="erpDocuments.Invoice">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                class="iconify iconify--mdi" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"
                @click="showModal('14')">
                <g fill="none">
                    <path
                        d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
                    <path :fill="getStatus('DocEntryStockTransfer')"
                        d="M8.56 11.9a1.5 1.5 0 0 1 0 2.12l-.974.976H16a1.5 1.5 0 0 1 0 3H7.586l.975.974a1.5 1.5 0 1 1-2.122 2.122l-3.535-3.536a1.5 1.5 0 0 1 0-2.121l3.535-3.536a1.5 1.5 0 0 1 2.122 0Zm6.88-9a1.5 1.5 0 0 1 2.007-.104l.114.103l3.535 3.536a1.5 1.5 0 0 1 .103 2.007l-.103.114l-3.535 3.536a1.5 1.5 0 0 1-2.225-2.008l.103-.114l.975-.974H8a1.5 1.5 0 0 1-.144-2.994L8 5.996h8.414l-.975-.975a1.5 1.5 0 0 1 0-2.122Z" />
                </g>
            </svg>
        </el-tooltip>
        <el-tooltip content="Contas a Receber" effect="light" :open-delay="300" placement="top"
            v-if="erpDocuments.IncomingPayment && taxOperation == 'Nota de venda'">
            <el-dropdown trigger="click">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                    class="iconify iconify--mdi" width="20" height="20" preserveAspectRatio="xMidYMid meet"
                    viewBox="0 0 24 24" @click="showModal('5')">
                    <path :fill="getStatus('DocEntryCR')"
                        d="M7 15h2c0 1.08 1.37 2 3 2s3-.92 3-2c0-1.1-1.04-1.5-3.24-2.03C9.64 12.44 7 11.78 7 9c0-1.79 1.47-3.31 3.5-3.82V3h3v2.18C15.53 5.69 17 7.21 17 9h-2c0-1.08-1.37-2-3-2s-3 .92-3 2c0 1.1 1.04 1.5 3.24 2.03C14.36 11.56 17 12.22 17 15c0 1.79-1.47 3.31-3.5 3.82V21h-3v-2.18C8.47 18.31 7 16.79 7 15Z">
                    </path>
                </svg>
            </el-dropdown>
        </el-tooltip>
        <el-tooltip content="Adiantamento de Cliente" effect="light" :open-delay="300" placement="top"
            v-if="erpDocuments.DownPayment">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img"
                class="iconify iconify--mdi" width="20" height="20" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"
                @click="showModal('8')" :disabled="erpDocuments.DownPayment === 0">
                <path :fill="getStatus('DocEntryAdto')"
                    d="M11 8c0 2.21-1.79 4-4 4s-4-1.79-4-4s1.79-4 4-4s4 1.79 4 4m0 6.72V20H0v-2c0-2.21 3.13-4 7-4c1.5 0 2.87.27 4 .72M24 20H13V3h11v17m-8-8.5a2.5 2.5 0 0 1 5 0a2.5 2.5 0 0 1-5 0M22 7a2 2 0 0 1-2-2h-3c0 1.11-.89 2-2 2v9a2 2 0 0 1 2 2h3c0-1.1.9-2 2-2V7Z">
                </path>
            </svg>
        </el-tooltip>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    props: {
        documents: {
            type: Array | String | Number | Boolean | Object,
            default: () => ({
                "Number": null,
                "DocEntry": null,
                "DocEntryAdto": null,
                "DocEntryCR": null,
                "DocEntryNf": null,
                "DocEntryPicking": null,
                "DocEntryNfTransfer": null,
                "DocEntryStockTransfer": null,
                "DocEntryPurchaseDeliveryTransfer": null,
                "DocEntryPurchaseInvoiceTransfer": null
            })
        },
        erpDocuments: {
            type: Array | String | Number | Boolean | Object,
            default: () => ({
                "IncomingPayment": false,
                "Invoice": false,
                "DownPayment": false,
                "Picking": false,
                "InvoiceTransfer": false,
                "PurchaseDeliveryNotes": false,
                "PurchaseInvoice": false
            })
        },
        taxOperation: { type: String }
    },
    data() {
        return {
            contentDocumentTransfer: "Documentos de Transferência",
        }
    },
    computed: {
        ...mapState(['ecommerce', "realm"])
    },
    methods: {
        getStatusDocumentTransfers() {
            return (this.documents.DocEntryNfTransfer !== 0 &&
                this.documents.DocEntryPurchaseDeliveryTransfer !== 0 &&
                this.documents.DocEntryPurchaseInvoiceTransfer !== 0);
        },
        getStatus(prop) {
            switch (prop) {
                case 'DocEntry':
                    return this.documents.DocEntry !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryCR':
                    return this.documents.DocEntryCR !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryNf':
                    return this.documents.DocEntryNf !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryPicking':
                    return this.documents.DocEntryPicking !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryAdto':
                    return this.documents.DocEntryAdto !== 0 ? "#00ADB7" : "#888888";
                case 'DocumentTransfers':
                    return this.getStatusDocumentTransfers() ? "#00ADB7" : "#888888";
                case 'DocEntryNfTransfer':
                    return this.documents.DocEntryNfTransfer !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryStockTransfer':
                    return this.documents.DocEntryStockTransfer !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryPurchaseDeliveryTransfer':
                    return this.documents.DocEntryPurchaseDeliveryTransfer !== 0 ? "#00ADB7" : "#888888";
                case 'DocEntryPurchaseInvoiceTransfer':
                    return this.documents.DocEntryPurchaseInvoiceTransfer !== 0 ? "#00ADB7" : "#888888";
            }
        },
        showModal(prop) {
            if (this.documents && this.documents.Number) {
                const number = this.documents.Number.toString();
                this.$router.push({
                    name: 'DocumentFulfillmentJson',
                    params: { number, type: prop },
                });
            }
        },
        getContentDocEntryNF() {
            return this.taxOperation === "Retorno para Transferência" ? "Nota Fiscal de Devolução" : "Nota Fiscal";
        },
        showIcon() {
            this.taxOperation != 'Remessa para Transferência' && this.taxOperation != 'Retorno para Transferência'
        }
    }
}
</script>

<style lang="scss" scoped>.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
    background-color: #F5F7FA !important;
    color: #1d253b !important;
}</style>
