<template>
    <div class="row" style="float: right">
        <span style="margin: 0 10pt">
            <base-input>
                <el-select v-model="filters.TypeRule" placeholder="Tipo de Regra">
                    <el-option v-for="item in typeOptions" :key="item.Key" :label="item.Label" :value="item.Key">
                    </el-option>
                </el-select>
            </base-input>
        </span>
        <span style="margin: 0 10pt">
            <base-input>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Campo do Ecommerce"
                    v-model="filters.platformField">
                </el-input>
            </base-input>
        </span>
        <span style="margin: 0 10pt">
            <base-input>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Campo do Erp" v-model="filters.erpField">
                </el-input>
            </base-input>
        </span>
        <span style="margin: 0 10pt">
            <button class="custom-btn-filter" @click="filter">Filtrar</button>
        </span>
        <span style="margin: 0 10pt">
            <button class="custom-btn-filter" @click="clearFilter">
                Limpar filtro
            </button>
        </span>
        <span style="margin: 0 10pt">
            <el-dropdown split-button>
                Novo
                <template #dropdown>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-if="getUserPermission('30_c')">
                            <span @click="$router.push({ name: 'OrderRuleCreate' })">Regras de Pedido</span>
                        </el-dropdown-item>
                        <el-dropdown-item v-if="getUserPermission('00_c')">
                            <span @click="$router.push({ name: 'CustomFieldCreate' })"> Campo Customizado </span>
                        </el-dropdown-item>
                    </el-dropdown-menu>
                </template>
            </el-dropdown>
        </span>
    </div>
</template>
<script>

import constants from '@/util/constants';

export default {
    data() {
        return {
            filters: {
                From: null,
                To: null,
                platformField: null,
                erpField: null,
                typeRule: null
            },
            typeOptions: constants.CustomFieldTypeRule
        };
    },
    methods: {
        filter() {
            this.$emit("filter");
        },
        clearFilter() {
            this.filters = {
                From: null,
                To: null,
                TaxOperation: null
            };
            this.$emit("filter");
        },
        value() {
            return this.filters;
        },
        getUserPermission(role) {
            return this.$keycloak.hasRealmRole(role);
        }
    },
};
</script>
<style>
.el-button-group .el-button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background-color: transparent;
    color: #00b7a9;
    border: #00b7a9 solid 1px;
    margin-bottom: 10px;
}

.el-button-group>.el-button:not(:last-child) {
    margin-right: -1px;
    background-color: transparent;
    color: #00b7a9;
    border: #00b7a9 solid 1px;
    margin-bottom: 10px;
}
</style>
