<template>
  <div class="ml-3 mr-3">
    <div class="row">
      <div class="col-12 col-lg-2">
        <base-input readonly label="Nº Pedido" v-model="orderCurrent.Number"></base-input>
      </div>
      <div class="col-12 col-lg-2">
        <base-input label="Status" readonly :value="orderCurrent.Status.AliasName"></base-input>
      </div>
      <div class="col-12 col-lg-2">
        <base-input label="Loja" readonly :value="orderCurrent.EcommerceName"></base-input>
      </div>
      <div class="col-12 col-lg-3">
        <base-input label="Data de criação">
          <el-date-picker readonly format="dd/MM/yyyy HH:mm" v-model="orderCurrent.PurchaseDate">
          </el-date-picker>
        </base-input>
      </div>
      <div class="col-12 col-lg-3">
        <base-input label="Data de importação">
          <el-date-picker readonly format="dd/MM/yyyy HH:mm" v-model="orderCurrent.ImportedDate">
          </el-date-picker>
        </base-input>
      </div>
      <div v-if="orderCurrent.NFKey" class="col-12 col-lg-4">
        <base-input label="Chave NF" readonly :value="orderCurrent.NFKey">
          <template v-slot:addonRight>
            <el-tooltip content="Clique para consultar a NF no site da Sefaz." effect="light" :open-delay="100"
              placement="top">
              <a :href="`https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=resumo&nfe=${orderCurrent.NFKey}`"
                target="_blank" class="like !important btn-info pt-2 pl-1 pr-1" size="sm" icon>
                <i class="material-icons">open_in_browser</i>
              </a>
            </el-tooltip>
          </template>
        </base-input>
      </div>
      <div class="col-12 col-lg-2">
        <base-input label="Nº NF" readonly :value="orderCurrent.NFNumber"></base-input>
      </div>
      <div class="col-12 col-lg-2">
        <base-input label="N° Interno Pedido SAP" readonly :value="orderCurrent.DocEntry"></base-input>
      </div>
      <div class="col-12 col-lg-2">
        <base-input label="N° Pedido SAP" readonly :value="orderCurrent.DocNumOrder"></base-input>
      </div>
      <div class="col-12 col-lg-2">
        <base-input label="Código do Cliente" readonly :value="orderCurrent.CardCode"></base-input>
      </div>
      <div class="col-12 col-lg-3">
        <base-input label="Código Rastreamento" readonly :value="orderCurrent.TrackingCode"></base-input>
      </div>
      <div class="col-12 col-lg-3">
        <base-input label="Canal de venda" id="channel" :readonly="orderCurrent.Status.BahnReference !== 'error'"
          @change="changeChannel($event)" :value="orderCurrent.Channel"></base-input>
      </div>
      <div class="col-12 col-lg-4">
        <base-input label="N° Canal de venda" readonly :value="orderCurrent.ChannelOrderID"></base-input>
      </div>
      <div class="col-12 col-lg-2">
        <div v-if="shippingError && orderNotIntegrated">
          <label>Método de Envio</label>
          <div>
            <el-select ref="shippingMethod" id="shippingMethod" name="Método de Envio" label="Método de Envio"
              @change="changeShippingMethod($event)" placeholder="Método de Envio"
              v-model="orderCurrent.Shipping.Method">
              <el-option class="select-primary" v-for="(item, index) in shippingMethods" :key="index" :label="item.name"
                :value="item.value"></el-option>
            </el-select>
            <span style="font-size: 10px; display: flex">
              <p style="color: #f22435; padding-right: 5px">
                Método de envio não encontrado
              </p>
              <a href="javascript:void(0)" @click="$router.push({ name: 'ShippingMethods' })">
                <i class="tim-icons icon-simple-add" style="padding: 5px; font-size: 5pt"></i>
                <strong>Cadastrar</strong>
              </a>
            </span>
          </div>
        </div>
        <div v-else>
          <el-tooltip :content="orderCurrent.Shipping.ErpReference" placement="bottom-start">
            <base-input label="Modo de envio" readonly :value="orderCurrent.Shipping.Method"></base-input>
          </el-tooltip>
        </div>
      </div>
      <div class="col col-lg-2">
        <base-input label="Código depósito" :readonly="!changeWarehouse">
          <el-select :disabled="!!(
            !changeWarehouse ||
            orderCurrent.DocEntry ||
            orderCurrent.DocEntryNfTransfer
          )
            " v-model="orderCurrent.WarehouseCode" @change="changeBplId">
            <el-option v-for="item in warehouses" :key="item.Id" :label="item.Code + ' - ' + item.WarehouseEcommerce"
              :value="item.Code">
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col col-lg-2">
        <base-input readonly label="Código filial" v-model="orderCurrent.BplID"></base-input>
      </div>
      <el-divider></el-divider>
      <div class="col-12 col-lg-2 mt-1">
        <label class="align-middle"><i class="material-icons mr-1">monetization_on</i> Valor do frete</label>
        <h4 class="strong">{{ orderCurrent.Shipping.Price | toCurrency }}</h4>
      </div>
      <div class="col-12 col-lg-2 mt-1">
        <label class="align-middle"><i class="material-icons mr-1">monetization_on</i> Descontos</label>
        <h4 class="strong">{{ sumDiscount | toCurrency }}</h4>
      </div>
      <div class="col-12 col-lg-2 mt-1">
        <label class="align-middle"><i class="material-icons mr-1">monetization_on</i> Total do pedido</label>
        <h4 class="strong">{{ sumPaymentValues | toCurrency }}</h4>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  props: {
    shippingMethods: { type: Array },
    paymentMethods: { type: Array },
    changeWarehouse: { type: Boolean },
    paymentError: { type: Boolean },
    shippingError: { type: Boolean },
  },
  data() {
    return {
      warehouses: [],
    };
  },
  computed: {
    ...mapState("orders", ["orderCurrent"]),
    sumPaymentValues() {
      let total = 0;

      if (!this.orderCurrent.OrderPayments) return total;

      this.orderCurrent.OrderPayments.forEach((payment) => {
        total += payment.Total;
      });

      return total;
    },
    orderNotIntegrated() {
      return this.orderCurrent.Status.BahnReference !== "integrated" ? true : false;
    },
    sumDiscount() {
      let discount = 0;

      if (!this.orderCurrent.OrderPayments) return discount;

      this.orderCurrent.OrderPayments.forEach((payment) => {
        discount += payment.Discount;
      });

      return discount;
    },
  },
  methods: {
    ...mapActions("configurations", ["getWarehouses"]),
    ...mapMutations("orders", [
      "UPDATE_PROPERTY_ORDER",
      "UPDATE_PROPERTY_PAYMENT_METHOD",
      "UPDATE_PROPERTY_SHIPPING_METHOD",
      "SET_NEW_CHANNEL"
    ]),
    changeBplId(code) {
      let warehouse = this.warehouses.find((a) => a.Code === code);
      this.UPDATE_PROPERTY_ORDER({ property: "BplID", newValue: warehouse.Filial });
    },
    changeShippingMethod(value) {
      this.UPDATE_PROPERTY_SHIPPING_METHOD(value);
    },
    changePaymentMethod(value) {
      this.UPDATE_PROPERTY_PAYMENT_METHOD(value);
    },
    changeChannel(value) {
      this.SET_NEW_CHANNEL(value.target.value);
    },
  },
  mounted() {
    this.getWarehouses()
      .then((res) => {
        this.warehouses = res.data;
      })
      .catch((error) => {
        this.$showError(`Erro ao buscar Warehouses, motivo: ${error.response.data}`);
      });
  },
};
</script>
<style>
.align-middle {
  display: flex;
  align-items: center;
}
</style>
